import { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Grid, Stack } from '@mui/material';
import { shallow } from 'zustand/shallow';
import MDBox from 'components/MDBox';
import MDButton from 'components/MDButton';
import MDTypography from 'components/MDTypography';
import { ToastType, createBackendUserService, notice, useBoolean, useUser } from 'features/common';
import { BasePasswordFormField } from 'features/common/components/BasePasswordFormField';
import { CreatePinCodeSchema, createPinCodeValidationFormSchema } from 'features/profile/templates';
import { useLockScreen } from 'features/store';
import { DialogWindow } from 'features/common/components/DialogWindow';
import { useResponsive } from 'features/common/hooks/useResponsive';
import { RequirementsList } from '../RequirementsList';
import { PIN_CODE_REQUIREMENTS } from './PinCode';

export const PinCodeForm: FC = () => {
  const { createUserPinCode, deactivateUserPinCode } = createBackendUserService();
  const { isPinCodeActive, connectBackendUserHandler } = useUser();

  const {
    register,
    formState: { isDirty, isValid, errors, isSubmitting },
    handleSubmit,
    reset,
    setValue,
    watch,
  } = useForm<CreatePinCodeSchema>({
    resolver: yupResolver(createPinCodeValidationFormSchema),
    mode: 'onTouched',
    defaultValues: { pinCode: '', pinCodeConfirmation: '' },
  });

  const { setIsDeactivateIdleTimer, onGetTimeToIdleHandler, registerDeviceHandler, resetStateHandler } = useLockScreen(
    state => ({
      onGetTimeToIdleHandler: state.onGetTimeToIdleHandler,
      registerDeviceHandler: state.registerDeviceHandler,
      resetStateHandler: state.resetStateHandler,
      setIsDeactivateIdleTimer: state.setIsDeactivateIdleTimer,
    }),
    shallow
  );

  // set pin => save in LS timestamp
  // close tab => open tab if timestamp ==> idle value ==> redirect to lock screen
  // but what if

  const onSubmit = handleSubmit(async formData => {
    try {
      await createUserPinCode({ pinCode: formData.pinCode, pinCodeConfirm: formData.pinCodeConfirmation });
      await registerDeviceHandler();
      await onGetTimeToIdleHandler();
      await connectBackendUserHandler();
      reset();
      notice(ToastType.SUCCESS, `PIN code has been successfully set up!`);
      return;
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 422) {
        notice(ToastType.ERROR, error?.response.data?.['hydra:description']);
        return;
      }
      notice(ToastType.ERROR, `Failed to set up PIN code, please try again!`);
    }
  });

  const isButtonDisabled = isPinCodeActive ? !isValid || isSubmitting || !isDirty : !isValid || isSubmitting;

  const [isModalOpen, openModal, closeModal] = useBoolean(false);
  const [isDeactivating, setIsDeactivating] = useState(false);

  const onDeactivatePinCodeHandler = async () => {
    try {
      setIsDeactivating(true);
      await deactivateUserPinCode();
      await connectBackendUserHandler();
      setIsDeactivateIdleTimer();
      reset();
      closeModal();
      setIsDeactivating(false);
      resetStateHandler();

      notice(ToastType.SUCCESS, 'PIN code has been successfully deleted!');
    } catch (error) {
      setIsDeactivating(false);
      console.error(error);
      notice(ToastType.ERROR, 'Failed to delete PIN code, please try again!');
    }
  };

  const isSmallScreen = useResponsive('down', 'sm');

  const onChangeInputHandler = (key: keyof CreatePinCodeSchema) => (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length > 4) return;

    const result = value.replace(/\D/g, '');

    setValue(key, result, { shouldDirty: true });
  };

  return (
    <MDBox component="form" pb={3} px={3} onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <BasePasswordFormField
            formInputProps={{
              ...register('pinCode'),
              label: 'PIN code',
              onChange: onChangeInputHandler('pinCode'),
              value: watch('pinCode'),
              fullWidth: true,
            }}
            errorValue={errors?.['pinCode']?.message}
          />
        </Grid>
        <Grid item xs={12}>
          <BasePasswordFormField
            formInputProps={{
              ...register('pinCodeConfirmation'),
              label: 'Confirm PIN code',
              fullWidth: true,
              value: watch('pinCodeConfirmation'),
              onChange: onChangeInputHandler('pinCodeConfirmation'),
            }}
            errorValue={errors?.['pinCodeConfirmation']?.message}
          />
        </Grid>
      </Grid>

      <MDBox mt={2} mb={1}>
        <MDTypography variant="h5">PIN code requirements</MDTypography>
      </MDBox>

      <Stack justifyContent="space-between" direction={{ sx: 'column', sm: 'row' }}>
        <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 1, sm: 0 }}>
          <RequirementsList requirements={PIN_CODE_REQUIREMENTS} />
        </MDBox>
        <MDBox sx={{ xs: { display: 'flex', width: 1, ml: 0 }, sm: { display: 'block', ml: 'auto', width: 'auto' } }}>
          <Stack direction={{ xs: 'column', sm: 'row' }} width={1} alignItems="center" spacing={1.5}>
            {isPinCodeActive && (
              <MDButton
                fullWidth={isSmallScreen}
                onClick={openModal}
                variant="gradient"
                color="dark"
                size="small"
                type="button"
              >
                delete pin
              </MDButton>
            )}

            <MDButton
              isLoading={isSubmitting}
              disabled={isButtonDisabled}
              variant="gradient"
              color="info"
              size="small"
              type="submit"
              fullWidth={isSmallScreen}
            >
              {isPinCodeActive ? 'update' : 'create'} pin
            </MDButton>
          </Stack>
        </MDBox>
      </Stack>
      <DialogWindow
        onCloseDialogHandler={closeModal}
        onApproveDialogHandler={onDeactivatePinCodeHandler}
        isDialogOpen={isModalOpen}
        dialogTitle="Are you sure you want to delete your current PIN code?"
        isLoading={isDeactivating}
      />
    </MDBox>
  );
};
