import { FC, useEffect, useState } from 'react';
import { Card, CardContent, Grid, LinearProgress, Stack } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { shallow } from 'zustand/shallow';
import Cookies from 'js-cookie';
import {
  LAST_MOBILE_ALERT_POPUP_TIME_KEY,
  ToastType,
  controller,
  createBackendUserService,
  notice,
  resetAbortContoller,
  useRouter,
  useUser,
} from 'features/common';
import { useAudioVideoChat, useLockScreen, useNotificationsCenter } from 'features/store';
import MDTypography from 'components/MDTypography';
import { setLayout, useMaterialUIController } from 'context';
import { ROUTES } from 'features/common/variables/routes';
import { firebaseAuth, unregisterSW } from 'features/auth';
import { signInWithCustomToken } from 'firebase/auth';
import { BaseCodeInputFields } from 'features/common/components/BaseCodeInputFields';
import { resetAllStores } from 'features/store/createStore';
import { FormValuesProps, codesValidatationSchema, defaultValues } from './form.config';

const FIELDS_AMOUNT = 4;

export const LockScreen: FC = () => {
  const { checkPinCodeAccess, connectPushNotification } = createBackendUserService();

  const { backendUserFirebaseId } = useUser();

  const [isDisabledBackRoute, setisDisabledBackRoute] = useState(true);

  const { pushToken, setPushNotificationId } = useNotificationsCenter(
    state => ({
      pushToken: state.pushNotificationData?.firebaseToken,
      setPushNotificationId: state.setPushNotificationId,
    }),
    shallow
  );

  const { setEventId, setStartCallProps } = useAudioVideoChat(state => ({
    setEventId: state.setEventId,
    setStartCallProps: state.setStartCallProps,
  }));

  const { state, navigate, back } = useRouter();

  const [_, dispatch] = useMaterialUIController();

  const {
    setCallData,
    setActivityTime,
    callData,
    isRedirectToLockScreen,
    setIsRedirectToLockScreen,
    setIsResetIdleTimer,
    deviceId,
  } = useLockScreen(
    state => ({
      setIsResetIdleTimer: state.setIsResetIdleTimer,
      setIsRedirectToLockScreen: state.setIsRedirectToLockScreen,
      deviceId: state.deviceId,
      isRedirectToLockScreen: state.isRedirectToLockScreen,
      callData: state.callData,
      setCallData: state.setCallData,
      setActivityTime: state.setActivityTime,
    }),
    shallow
  );

  useEffect(() => {
    if (!isRedirectToLockScreen && !isDisabledBackRoute) navigate(state?.prevRoute || ROUTES.myLegalCases);
  }, [isRedirectToLockScreen, isDisabledBackRoute]);

  useEffect(() => {
    if (!isRedirectToLockScreen) back();

    setLayout(dispatch, 'page');
  }, []);

  const {
    control,
    handleSubmit,
    setFocus,
    register,
    setValue,
    watch,
    reset,
    formState: { isSubmitting },
  } = useForm<FormValuesProps>({
    mode: 'onTouched',
    resolver: yupResolver(codesValidatationSchema),
    defaultValues,
  });

  const onFormSubmitHandler = handleSubmit(async formData => {
    if (controller?.signal?.aborted) resetAbortContoller();

    if (!deviceId || !isRedirectToLockScreen) {
      back();
      notice(ToastType.SUCCESS, 'Welcome back!');
      return;
    }
    try {
      const pinCode = Object.values(formData).reduce((acc, el) => {
        acc += el;
        return acc;
      }, '');

      const { data } = await checkPinCodeAccess({
        firebaseUuid: backendUserFirebaseId,
        pinCode,
        deviceUuid: deviceId,
        ...(pushToken && { pushToken }),
      });

      await signInWithCustomToken(firebaseAuth, data?.token);
      if (pushToken) {
        const { data } = await connectPushNotification(pushToken);
        setPushNotificationId(data?.['@id']);
      }
      setisDisabledBackRoute(false);
      setIsRedirectToLockScreen(false);

      notice(ToastType.SUCCESS, 'Welcome back!');

      if (callData?.eventId && callData?.endParticipantFirebaseId) {
        setEventId(callData?.eventId);
        setStartCallProps({
          endParticipantFirebaseId: callData?.endParticipantFirebaseId,
          eventType: callData?.eventType,
          caseId: '',
        });

        setCallData({ endParticipantFirebaseId: '', eventId: '', eventType: 'video_call' });

        navigate(callData?.eventType === 'voice_call' ? ROUTES.audioRoom : ROUTES.videoRoom, {
          state: {
            prevRoute: ROUTES.myLegalCases,
          },
        });
      } else {
        setIsResetIdleTimer(true);
      }
      setActivityTime(Date.now());
    } catch (error: any) {
      console.error(error);
      if (error?.response?.status === 422) {
        reset();
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        setIsResetFocus(true);
        return;
      }

      if (error?.response?.status === 401) {
        notice(ToastType.ERROR, error?.response?.data?.['hydra:description']);
        await firebaseAuth.signOut();
        resetAllStores();
        await unregisterSW();
        Cookies.remove(LAST_MOBILE_ALERT_POPUP_TIME_KEY);
        navigate(ROUTES.signIn);
        return;
      }
      reset();
      setIsResetFocus(true);
      notice(ToastType.ERROR, 'Something went wrong, please try again!');
    }
  });

  useEffect(() => {
    const subscription = watch(value => {
      if (Object.values(value)?.[FIELDS_AMOUNT - 1]) {
        onFormSubmitHandler();
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, onFormSubmitHandler]);

  const [isResetFocus, setIsResetFocus] = useState(false);

  useEffect(() => {
    if (isResetFocus) {
      setFocus('code1');
      setIsResetFocus(false);
    }
  }, [isResetFocus]);

  return (
    <Stack
      component="section"
      sx={{
        p: 3,
        position: 'relative',
        minHeight: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid container>
        <Grid item xs={12}>
          <Card sx={{ py: 2, px: 1, height: 1, maxWidth: 600, margin: '0 auto' }}>
            <CardContent>
              <Stack
                component="form"
                onSubmit={onFormSubmitHandler}
                spacing={2}
                justifyContent="center"
                alignItems="center"
              >
                <MDTypography variant="h2" textAlign="center" fontSize={{ xs: '24px', md: '32px' }}>
                  Enter the 4-digit PIN code
                </MDTypography>

                <Stack direction="row" spacing={2} justifyContent="center">
                  <BaseCodeInputFields
                    control={control}
                    numberOfFields={FIELDS_AMOUNT}
                    register={register}
                    setFocus={setFocus}
                    setValue={setValue}
                    isDisabled={isSubmitting}
                  />
                </Stack>
                {isSubmitting && (
                  <Stack width={1} justifyContent="center" alignItems="center" overflow="hidden">
                    <LinearProgress sx={{ width: 1, borderRadius: 0 }} color="info" />
                  </Stack>
                )}
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Stack>
  );
};
